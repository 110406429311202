import Error404 from 'components/errors/404';
import ErrorLayout from 'components/layout/ErrorLayout';
import NextPage from 'types/NextPage';

/**
 * Страница 404 ошибки.
 */
const Error404Page: NextPage = () => {
  return <Error404 />;
};

Error404Page.Layout = ErrorLayout;

export default Error404Page;
