import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

/**
 * Отображает страницу 404 ошибки.
 */
const Error404: FC = () => {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Ошибка 404. Страница не найдена</Typography>
      </Box>
      <Typography variant="body1">
        Пожалуйста, проверьте правильность адреса или обратитесь в службу
        поддержки.
      </Typography>
    </>
  );
};

export default Error404;
