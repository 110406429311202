import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core';

import { TasksContextProvider } from 'components/contexts/TasksContext';
import useSession from 'hooks/useSession';

import Content from './content/Content';
import Footer from './footer/Footer';
import Header from './header/Header';
import HeaderSimple from './header/HeaderSimple';

/**
 * Возвращает коллекцию классов компонента.
 */
const useStyles = makeStyles(() => ({
  header: {
    flex: 0,
  },

  content: {
    flex: 1,
  },

  footer: {
    flex: 0,
  },
}));

/**
 * Обёртка для страниц отображения ошибок.
 */
const ErrorLayout: FC = ({ children }) => {
  const { isAuthenticated, isReady } = useSession();
  const classes = useStyles();

  if (!isReady) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <>
        <HeaderSimple className={classes.header} />
        <Content className={classes.content}>{children}</Content>
        <Footer className={classes.footer} />
      </>
    );
  }

  return (
    <TasksContextProvider>
      <Header className={classes.header} />
      <Content className={classes.content}>{children}</Content>
      <Footer className={classes.footer} />
    </TasksContextProvider>
  );
};

export default ErrorLayout;
