import { FC, ComponentPropsWithoutRef } from 'react';

import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import { ToolbarRoot } from 'components/portals/ToolbarPortal';

import BrandIcon from '../logo/BrandIcon';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof AppBar>;

/**
 * Возвращает классы компонента.
 */
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    zIndex: theme.zIndex.drawer + 1,
  },

  primaryToolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

/**
 * Отображает упрощенную шапку сайта.
 */
const HeaderSimple: FC<Props> = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <AppBar
      {...props}
      className={cn(classes.root, className)}
      position="sticky"
      component="header"
    >
      <Toolbar className={classes.primaryToolbar}>
        <BrandIcon />
      </Toolbar>
      <ToolbarRoot />
    </AppBar>
  );
};

export default HeaderSimple;
